import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import {useAuth} from '../providers/AuthProvider';
import {useStatus} from '../providers/MsgStatusProvider';

const MastodonConnectButton = () => {
    const [open, setOpen] = useState(false);
    const [instanceUrl, setInstanceUrl] = useState('https://mastodon.social/');
    const {token} = useAuth();
    const {setStatusType, setStatusMessage, setIsAlertOpen} = useStatus();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setInstanceUrl('');
    };

    const handleConnect = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}connect/mastodon/auth/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify({instance_url: "https://mastodon.social/"})
            });

            const data = await response.json();

            if (data.redirect_url) {
                window.location.href = data.redirect_url;
            } else {
                setStatusMessage('Failed to connect to Mastodon')
                setIsAlertOpen(true)
                setStatusType('error')

            }
        } catch (error) {
            setStatusMessage('Error connecting to Mastodon')
            setIsAlertOpen(true)
            setStatusType('error')

        }
        handleClose();
    };

    return (
        <>
            <Button
                variant="contained"
                onClick={handleClickOpen}
            >
                Connect Mastodon
            </Button>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Connect Mastodon Account</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Mastodon Instance URL"
                        placeholder="https://mastodon.social"
                        type="url"
                        fullWidth
                        variant="outlined"
                        // value={instanceUrl}
                        value="https://mastodon.social/"
                        onChange={(e) => setInstanceUrl(e.target.value)}
                        sx={{mt: 2}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConnect}
                        color="primary"
                        // disabled={!instanceUrl}
                    >
                        Connect
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MastodonConnectButton; 