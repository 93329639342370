import React from 'react';
import {Button, CardActions} from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import {Link} from "react-router-dom";
import Grid from "@mui/material/Grid";

const TemplateCard = ({template}) => {
    return (
        <Card>
            <CardMedia
                component="img"
                image={template.preview_img}
                title={template.name}
            />
            <CardActions sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Grid item>
                    {template.name}
                </Grid>
                <Grid item>
                    <Button sx={{marginRight: 2}} component={Link} variant="outlined" to={`/editor/${template.id}`}>
                        Edit
                    </Button>
                    <Button variant="contained" component={Link} to={`/template/create/${template.id}`}>
                        Create
                    </Button>
                </Grid>
            </CardActions>
        </Card>
    );
};


export default TemplateCard;
