import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { useAuth } from "../providers/AuthProvider";
import { useStatus } from "../providers/MsgStatusProvider";

const BlueSkyConnectButton = () => {
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const auth = useAuth();
  const { setStatusMessage, setIsAlertOpen, setStatusType } = useStatus();

  const handleConnect = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    const url = `${process.env.REACT_APP_API_BASE_URL}connect/bluesky/`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${auth.token}`
      },
      body: JSON.stringify({ username, password })
    })
      .then(response => response.json())
      .then(data => {
        if (data.status) {
          setStatusMessage("BlueSky account connected successfully");
          setIsAlertOpen(true);
          setStatusType("success");
          handleClose();
        } else {
          throw new Error(data.message);
        }
      })
      .catch(error => {
        setStatusMessage(error.message);
        setIsAlertOpen(true);
        setStatusType("error");
      });
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleConnect}>
        Connect to BlueSky
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Connect BlueSky Account</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Username"
            type="text"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Connect</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BlueSkyConnectButton;