import React from 'react';
import { Navigate } from 'react-router-dom';
import { useFeatureAccess } from '../hooks/useFeatureAccess';
import { Typography } from '@mui/material';

export const FeatureGuard = ({
  children,
  requiredPermission,
  fallbackPath = '/dashboard',
  showError = false
}) => {
  const permissions = useFeatureAccess();

  if (!permissions[requiredPermission]) {
    if (showError) {
      return (
        <Typography color="error" align="center">
          This feature is not available in your current plan.
        </Typography>
      );
    }
    return <Navigate to={fallbackPath} replace />;
  }

  return children;
};