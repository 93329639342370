import React, {useCallback, useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Container,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {HexColorPicker} from "react-colorful";
import VideoEditor from "../components/VideoEditor";

const StyledPaper = styled(Paper)(({theme}) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    '&.disabled': {
        opacity: 0.5,
        pointerEvents: 'none',
        backgroundColor: theme.palette.action.disabledBackground,
    }
}));

const PreviewContainer = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(1),
    minHeight: '300px'
}));

const OriginalTemplateContainer = styled(Box)(({theme}) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2),
}));

const LoadingBackdrop = styled(Backdrop)(({theme}) => ({
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    flexDirection: 'column',
    gap: theme.spacing(2),
}));

const DownloadButton = styled(Button)(({theme}) => ({
    marginTop: theme.spacing(2),
}));

const TemplateCreate = () => {
    const {templateId} = useParams();
    const navigate = useNavigate();
    const [template, setTemplate] = useState(null);
    const [jsonData, setJsonData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [previewImage, setPreviewImage] = useState('');
    const [publishType, setPublishType] = useState('png');
    const [isRendering, setIsRendering] = useState(false);
    const [isVideoProcessing, setIsVideoProcessing] = useState(false);
    const [videoProcessed, setVideoProcessed] = useState(false);
    const [videoError, setVideoError] = useState('');
    const [colorPickerVisibility, setColorPickerVisibility] = useState({});
    const [activeStep, setActiveStep] = useState(1);

    useEffect(() => {
        const fetchTemplate = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}templates/${templateId}`, {
                    headers: {
                        'Authorization': `Token ${localStorage.getItem('token')}`
                    }
                });
                const data = await response.json();
                setTemplate(data);
                setJsonData(data.template);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching template:', error);
                setIsLoading(false);
            }
        };

        if (templateId) {
            fetchTemplate();
        }
    }, [templateId]);

    const handleColorClick = useCallback((childId) => {
        setColorPickerVisibility(prev => ({
            ...prev,
            [childId]: !prev[childId]
        }));
    }, []);

    const updateChildProperty = useCallback((childId, property, value) => {
        setJsonData(prevData => {
            const newData = {...prevData};
            const children = [...newData.pages[0].children];
            const index = children.findIndex(c => c.id === childId);

            if (index !== -1) {
                children[index] = {...children[index], [property]: value};
                return {
                    ...newData,
                    pages: [{...newData.pages[0], children}]
                };
            }
            return prevData;
        });
    }, []);

    const pollRenderJob = async (jobId) => {
        const pollInterval = setInterval(async () => {
            setIsRendering(true);
            try {
                const response = await fetch(
                    `https://api.polotno.com/api/renders/${jobId}?KEY=${process.env.REACT_APP_POLOTNO_DEV}`
                );
                const job = await response.json();

                if (job.status === 'done') {
                    clearInterval(pollInterval);
                    setPreviewImage(job.output);
                    setIsRendering(false);
                } else if (job.status === 'error') {
                    clearInterval(pollInterval);
                    console.error('Render job failed:', job.error);
                    setIsRendering(false);
                }
            } catch (error) {
                console.error('Error polling render job:', error);
                clearInterval(pollInterval);
            }
        }, 5000);
    };

    const handleGenerate = async () => {
        if (template?.is_video && !videoProcessed) {
            setVideoError('Please process the video before generating');
            return;
        }

        setIsRendering(true);
        setPreviewImage('');

        try {
            if (template?.is_video) {
                const response = await fetch(
                    `https://api.polotno.com/api/renders?KEY=${process.env.REACT_APP_POLOTNO_DEV}`,
                    {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({
                            design: jsonData,
                            format: publishType,
                            width: jsonData.width,
                            height: jsonData.height,
                            fps: 30,
                            pixelRatio: 1,
                            duration: jsonData.pages[0].duration
                        }),
                    }
                );
                const data = await response.json();
                if (data.id) {
                    await pollRenderJob(data.id);
                    return;
                }
            } else {
                const response = await fetch(
                    `https://api.polotno.com/api/render?KEY=${process.env.REACT_APP_POLOTNO_DEV}`,
                    {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({
                            design: jsonData,
                            outputFormat: 'url',
                            exportOptions: {},
                        }),
                    }
                );
                const data = await response.json();
                if (data?.url) {
                    setPreviewImage(data.url);
                }
            }
        } catch (error) {
            console.error('Error generating preview:', error);
        } finally {
            if (!template?.is_video) {
                setIsRendering(false);
            }
        }
    };

    const renderInputs = () => {
        if (!jsonData?.pages?.[0]?.children) return null;

        return jsonData.pages[0].children
            .filter(child => child.styleEditable === true)
            .map(child => {
                switch (child.type) {
                    case 'text':
                        return (
                            <FormControl fullWidth key={child.id} sx={{mb: 2}}>
                                <TextField
                                    label={child.text}
                                    value={child.text}
                                    onChange={(e) => updateChildProperty(child.id, 'text', e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Button
                                                    onClick={() => handleColorClick(child.id)}
                                                    sx={{
                                                        minWidth: 'auto',
                                                        p: 1,
                                                        backgroundColor: child.fill,
                                                        '&:hover': {backgroundColor: child.fill}
                                                    }}
                                                />
                                                {colorPickerVisibility[child.id] && (
                                                    <Box sx={{position: 'absolute', zIndex: 1, right: 0, top: '100%'}}>
                                                        <HexColorPicker
                                                            color={child.fill}
                                                            onChange={(color) => updateChildProperty(child.id, 'fill', color)}
                                                        />
                                                    </Box>
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        );

                    case 'image':
                        return (
                            <FormControl fullWidth key={child.id} sx={{mb: 2}}>
                                <TextField
                                    label="Image URL"
                                    value={child.src}
                                    onChange={(e) => updateChildProperty(child.id, 'src', e.target.value)}
                                    helperText="Enter the URL of the image"
                                />
                                {child.src && (
                                    <Box sx={{mt: 1}}>
                                        <img
                                            src={child.src}
                                            alt="Preview"
                                            style={{maxWidth: '200px', maxHeight: '200px'}}
                                        />
                                    </Box>
                                )}
                            </FormControl>
                        );

                    case 'video':
                        return (
                            <FormControl fullWidth key={child.id} sx={{mb: 2}}>
                                <VideoEditor
                                    onVideoProcess={(childId, url, duration) => {
                                        updateChildProperty(childId, 'src', url);
                                        updateChildProperty(childId, 'duration', duration);
                                        setVideoProcessed(true);
                                        setVideoError('');
                                        setActiveStep(3);
                                    }}
                                    onError={setVideoError}
                                    childId={child.id}
                                    initialVideoUrl={child.src}
                                    setVideoProcessed={setVideoProcessed}
                                />
                            </FormControl>
                        );

                    default:
                        return null;
                }
            });
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                <CircularProgress/>
            </Box>
        );
    }

    const handleDownload = async () => {
        try {
            const response = await fetch(previewImage);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;

            // Set filename based on content type
            const extension = template?.is_video && publishType === 'mp4' ? 'mp4' : 'png';
            link.download = `${template.name}-${Date.now()}.${extension}`;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        <Container maxWidth="lg">
            <Link to="/dashboard"
                  style={{
                      display: 'flex',
                      alignItems: 'center',
                      textDecoration: 'none',
                      marginBottom: 10
                  }}>
                <ArrowBackIosIcon sx={{mr: 0.5, fontSize: '10px'}} fontSize="small"/>
                Back to Templates
            </Link>
            <LoadingBackdrop open={isRendering}>
                <CircularProgress color="inherit"/>
                <Typography variant="h6" color="inherit">
                    Generating your content. Do not refresh this page. This may take a while...
                </Typography>
            </LoadingBackdrop>
            <Typography variant="h4" sx={{mb: 3}}>
                Create from Template: {template?.name}
            </Typography>

            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <OriginalTemplateContainer>
                        <Typography variant="h6" gutterBottom>
                            Original Template
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                maxHeight: '300px',
                                overflow: 'hidden'
                            }}
                        >
                            <img
                                src={template?.preview_img}
                                alt="Original template"
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '300px',
                                    objectFit: 'contain'
                                }}
                            />
                        </Box>
                    </OriginalTemplateContainer>
                </Grid>

                <Grid item xs={12} md={6}>
                    {template?.is_video ? (
                        <Box>
                            <StyledPaper className={activeStep < 1 ? 'disabled' : ''}>
                                <Typography variant="h6" gutterBottom>
                                    Step 1: Select Output Format
                                </Typography>
                                <RadioGroup
                                    row
                                    value={publishType}
                                    onChange={(e) => {
                                        setPublishType(e.target.value);
                                        setActiveStep(2);
                                    }}
                                >
                                    <FormControlLabel value="gif" control={<Radio/>} label="GIF"/>
                                    <FormControlLabel value="mp4" control={<Radio/>} label="Video"/>
                                </RadioGroup>
                            </StyledPaper>
                        </Box>
                    ) : null}

                    <StyledPaper className={template?.is_video && activeStep < 2 ? 'disabled' : ''}>
                        <Typography variant="h6" gutterBottom>
                            {template?.is_video ? 'Step 2: Customize Template' : 'Customize Template'}
                        </Typography>
                        {renderInputs()}
                    </StyledPaper>

                    <Box sx={{mt: 2, display: 'flex', gap: 2}}>
                        <Button
                            variant="contained"
                            onClick={handleGenerate}
                            disabled={isRendering}
                        >
                            {isRendering ? 'Generating...' : 'Generate Preview'}
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => navigate(-1)}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                    <PreviewContainer>
                        {previewImage ? (
                            <>
                                {template?.is_video ? (
                                    publishType === 'mp4' ? (
                                        <video width="100%" controls>
                                            <source src={previewImage} type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>
                                    ) : (
                                        <img
                                            src={previewImage}
                                            alt="Generated preview"
                                            style={{maxWidth: '100%', height: 'auto'}}
                                        />
                                    )
                                ) : (
                                    <>
                                        <img
                                            src={previewImage}
                                            alt="Generated preview"
                                            style={{maxWidth: '100%', height: 'auto'}}
                                        />
                                        <DownloadButton
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleDownload}
                                            disabled={isRendering}
                                        >
                                            Download {template?.is_video && publishType === 'mp4' ? 'Video' : 'Image'}
                                        </DownloadButton>
                                    </>
                                )}
                            </>
                        ) : (
                            <Typography variant="body1" color="textSecondary">
                                Preview will appear here after generating
                            </Typography>
                        )}
                    </PreviewContainer>
                </Grid>
            </Grid>
        </Container>
    )
        ;
};

export default TemplateCreate;