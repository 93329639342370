import React, { useEffect, useState } from 'react';
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useStatus } from "../providers/MsgStatusProvider";
import { useLocation } from 'react-router-dom';

function SocialConnectionTikTokPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const [tiktokDetailsSet, setTiktokDetailsSet] = useState(false);
    const { setStatusMessage, setIsAlertOpen, setStatusType } = useStatus();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('code');
        const state = searchParams.get('state');

        if (code && state) {
            const url = `${process.env.REACT_APP_API_BASE_URL}connect/tiktok/callback/`;
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    code: code,
                    state: state
                }),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    setTiktokDetailsSet(true);
                    navigate('/profile');
                    setStatusMessage("You've successfully connected to TikTok");
                    setIsAlertOpen(true);
                    setStatusType("success");
                } else {
                    console.error(data);
                    setStatusMessage("Failed to connect to TikTok");
                    setIsAlertOpen(true);
                    setStatusType("error");
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setStatusMessage("An error occurred while connecting to TikTok");
                setIsAlertOpen(true);
                setStatusType("error");
            });
        }
    }, [location, navigate, setIsAlertOpen, setStatusMessage, setStatusType]);

    return (
        <>
            <Typography variant="h1">
                Connecting to TikTok...
            </Typography>
            <Typography variant="h4">
                {tiktokDetailsSet
                    ? "You're connected to TikTok. You will be redirected shortly."
                    : "Please wait while we complete the connection process."}
            </Typography>
        </>
    );
}

export default SocialConnectionTikTokPage;