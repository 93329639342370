import React, {useEffect, useState} from "react";
import {SectionTab} from "polotno/side-panel";
import {observer} from "mobx-react-lite";
import ImageListItem from "@mui/material/ImageListItem";
import ImageList from "@mui/material/ImageList";

function useCustomTemplates(tempId) {
  const [templates, setTemplates] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const url = process.env.REACT_APP_API_BASE_URL + 'templates/';

  useEffect(() => {
    // Fetch all templates
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${localStorage.getItem('token')}`
      },
    })
    .then(response => response.json())
    .then(data => {
      setTemplates(data);
      // If tempId exists, find and set the current template
      if (tempId) {
        const filteredTemplate = data.filter(item => item.id === tempId);
        if (filteredTemplate) {
          setCurrentTemplate(filteredTemplate.template);
        }
      }
    })
    .catch(error => console.error(error));
  }, [tempId]);

  return { templates, currentTemplate };
}

const CustomSection = {
  name: 'Branded',
  Tab: (props) => (
    <SectionTab name="Branded" {...props}>
      Icon
    </SectionTab>
  ),
  Panel: observer(({ store, tempId }) => {
    const { templates, currentTemplate } = useCustomTemplates(tempId);

    // Load the current template when it's available
    useEffect(() => {
      if (currentTemplate) {
        store.loadJSON(currentTemplate.template);
      }
    }, [currentTemplate, store]);

    if (templates.length > 0) {
      return (
        <div style={{
          height: '100%',
          overflowX: 'scroll'
        }}>
          <p>Here's a list of your custom templates:</p>
          <ImageList cols={2} rowHeight={164}>
            {templates.map(template => (
              <ImageListItem 
                onClick={() => store.loadJSON(template.template)} 
                key={template.id}
              >
                <img
                  src={template.preview_img}
                  alt={template.id}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </div>
      );
    } else {
      return (
        <div>
          <p>Sorry, you haven't saved any templates yet.</p>
        </div>
      );
    }
  }),
};

export default CustomSection;
