import React from 'react';
import {Box, Button, Card, CardContent, Dialog, DialogContent, Grid, Typography} from '@mui/material';
import dayjs from 'dayjs';
import RenderSocialIcons from "./RenderSocialIcons";

const PostDetailsModal = ({open, onClose, post, onEdit}) => {
    if (!post) {
        return null;
    }

    const isScheduled = dayjs(post.start).isAfter(dayjs());
    const hasMedia = post.img_url || post.platform_details.some(detail => detail.platform_image_url);

    const renderMedia = () => {
        if (post.is_text_only) {
            return null;
        }

        const mediaUrl = post.img_url || post.image_url;

        if (post.is_video || post.is_gif) {
            return (
                <Box mt={1}>
                    <video
                        src={mediaUrl}
                        controls
                        style={{maxWidth: '100%', maxHeight: 200}}
                    />
                </Box>
            );
        }

        return (
            <Box mt={1}>
                <img
                    src={mediaUrl}
                    alt={post.title}
                    style={{maxWidth: '100%', maxHeight: 200}}
                />
            </Box>
        );
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogContent>
                <Card sx={{border: 'none', padding: 2}} elevation={0}>
                    <CardContent>
                        <Box mb={2}>
                            <Typography variant="subtitle2">
                                {isScheduled ? 'Scheduled for:' : ''} {dayjs(post.start).format('MMM D, YYYY h:mm a')}
                            </Typography>
                        </Box>

                        <Grid container spacing={2} alignItems="center">
                            {hasMedia && (
                                <Grid item xs={12} sm={4}>
                                    {renderMedia()}
                                </Grid>
                            )}
                            <Grid item xs={12} sm={hasMedia ? 8 : 12}>
                                <Typography variant="subtitle1">
                                    {post.post_text}
                                </Typography>
                                {isScheduled && (
                                    <Box mt={2}>
                                        <Button variant="contained" color="primary" onClick={() => onEdit(post)}>
                                            Edit Post
                                        </Button>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>

                        <Box mt={2}>
                            {post.platform_details && Array.isArray(post.platform_details) ? (
                                // renderSocialIcons()
                                <RenderSocialIcons post={post}/>
                            ) : (
                                <Typography variant="body2">No social account details available</Typography>
                            )}
                        </Box>
                    </CardContent>
                </Card>
            </DialogContent>
        </Dialog>
    );
};

export default PostDetailsModal;
