export const PLAN_FEATURES = {
    LITE_CREATOR: {
        name: 'Lite Creator',
        permissions: {
            canAccessTemplateEditor: false,
            canCreateMedia: false,
            canAccessImageGallery: false,
            canAccessSocialConnections: true,
            canCreatePosts: true
        }
    },
    DESIGN_TOOLS: {
        name: 'Design Tools',
        permissions: {
            canAccessTemplateEditor: true,
            canCreateMedia: true,
            canAccessImageGallery: true,
            canAccessSocialConnections: false,
            canCreatePosts: false
        },
    },
    ESSENTIAL_ACCESS: {
        name: 'Essential Access',
        permissions: {
            canAccessTemplateEditor: true,
            canCreateMedia: true,
            canAccessImageGallery: true,
            canAccessSocialConnections: true,
            canCreatePosts: true
        }
    },
    ELITE_ADVANTAGE: {
        name: 'Elite Advantage',
        permissions: {
            canAccessTemplateEditor: true,
            canCreateMedia: true,
            canAccessImageGallery: true,
            canAccessSocialConnections: true,
            canCreatePosts: true
        }
    },
};

// export const DEFAULT_PERMISSIONS = {
//     canAccessTemplateEditor: true,
//     canCreateMedia: true,
//     canAccessImageGallery: true,
//     canAccessSocialConnections: true,
//     canCreatePosts: true
// };