import React, {useEffect, useState} from 'react';
import {Alert, Button, FormControl, Grid, TextField, Typography,} from '@mui/material';
import {useAuth} from "../providers/AuthProvider";
import { useFeatureAccess } from "../hooks/useFeatureAccess";
import SocialAccountsDisplay from "../components/SocialAccountsDisplay";

export default function ProfilePage({user}) {
//   const classes = useStyles();
    const auth = useAuth();
    const { canAccessSocialConnections } = useFeatureAccess();
    const profile_url = process.env.REACT_APP_STRIPE_PPROFILE_URL

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
    });
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (auth.user) {
            setFormData({
                first_name: auth.user[0]?.first_name,
                last_name: auth.user[0]?.last_name,
                email: auth.user[0]?.email,
            });
        }
    }, [auth.user]);

    const handleChange = event => {
        const {name, value} = event.target;
        setFormData(prevFormData => ({...prevFormData, [name]: value}));
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setFormData({
            first_name: auth.user[0].first_name,
            last_name: auth.user[0].last_name,
            email: auth.user[0].email,
        });
        setIsEditing(false);
    };

    const handleSave = () => {
        // send form data to backend API to update user profile
        setIsEditing(false);
    };
    const renderSocialConnections = () => {
        if (!canAccessSocialConnections) {
            return (
                <Alert severity="info" sx={{ mb: 2 }}>
                    Social media connections are not available in your current plan. 
                    Please upgrade to connect social media accounts.
                </Alert>
            );
        }

        return (
            <>
                <Grid item xs={12} md={4}>
                    <Typography variant="p">
                        Connect to social media accounts to post to different platforms
                    </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    {['twitter', 'linkedin', 'instagram', 'threads', 'bluesky', 'tiktok', 'mastodon', 'facebook'].map((platform) => (
                        <Grid item xs={8} key={platform}>
                            <Typography variant="p" sx={{ textTransform: 'capitalize' }}>
                                {platform}
                            </Typography>
                            <hr/>
                            <SocialAccountsDisplay filtered_account={platform}/>
                        </Grid>
                    ))}
                </Grid>
            </>
        );
    };
    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    if (!auth.isLoading && auth.user) {

        return (
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h6">Account Details</Typography>
                        <hr/>
                    </Grid>
                    <Grid item xs={12} md={4} spacing={3} mt={3}>
                        <Typography variant="p">
                            Update your account details here
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>

                        <FormControl>
                            <TextField
                                label="First Name"
                                variant="outlined"
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleChange}
                            />
                            <TextField
                                label="Last Name"
                                variant="outlined"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleChange}
                            />
                            <TextField
                                label="Email"
                                variant="outlined"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />

                            <div>
                                <Button variant="contained" color="primary" onClick={handleSave}>
                                    Save
                                </Button>
                            </div>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6">Connected Accounts</Typography>
                            <hr/>
                        </Grid>
                        {renderSocialConnections()}
                    </Grid>                    
                </Grid>


                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h6">Billing Details</Typography>
                        <hr/>
                    </Grid>
                    <Grid item xs={12} md={4} spacing={3} mt={3}>
                        <Typography variant="p">
                            View account details
                        </Typography>

                    </Grid>
                    <Grid item xs={8}>
                        <a href={profile_url}>View your Payment details</a>
                    </Grid>
                </Grid>

            </div>
        );
    }
}