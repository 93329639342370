import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    Grid,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    InputAdornment,
    Radio,
    RadioGroup,
    Toolbar,
    Typography
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import TextField from "@mui/material/TextField";
import styled from "@emotion/styled";
import AppBar from "@mui/material/AppBar";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import {HexColorPicker} from "react-colorful";
import VideoEditor from "./VideoEditor";

const ImgViewWrapper = styled("div")(({theme}) => ({
    display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '300px',
}));

const ImageListViewWrapper = styled(ImageList)(({theme}) => ({
    height: '80vh', overflow: 'auto',
}));

const FeedbackWrapper = styled(ImageList)(({theme}) => ({
    margin: theme.spacing(3),
    padding: theme.spacing(5),
    backgroundColor: theme.palette.grey["40"],
    color: theme.palette.grey["100"],
    display: 'flex',
    borderRadius: theme.spacing(2), // minHeight: '90vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

const InputWrapper = styled('div')(({theme}) => ({
    margin: theme.spacing(2),
    padding: theme.spacing(5),
    backgroundColor: theme.palette.grey["80"],
    color: theme.palette.grey["100"],
    display: 'flex',
    borderRadius: theme.spacing(2),
    height: '80vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

// Add new styled component for steps
const StepContainer = styled(Box)(({theme}) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    maxWidth: '100%', // Add this
    width: '100%', // Add this
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
    },   
    '&.disabled': {
        opacity: 0.5, pointerEvents: 'none', backgroundColor: theme.palette.action.disabledBackground,
    }
}));


function CloseIcon() {
    return null;
}

const ImageGalleryDialog = ({open, onClose, setPostImg}) => {
    // const classes = useStyles();
    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [customInputs, setCustomInputs] = useState(null);
    const [customBackgroundInputs, setCustomBackgroundInputs] = useState(null);
    const [jsonData, setJsonData] = useState(null);
    const [previewNewImage, setPreviewNewImage] = useState('');
    const [initialImages, setInitialImages] = useState([]);
    // const [addImageBtn, setAddImageBtn] = useState(false);
    // const [displayColorPicker, setDisplayColorPicker] = useState(false); // to remove
    const [colorPickerVisibility, setColorPickerVisibility] = useState({});
    // const [forceUpdate, setForceUpdate] = useState(0);
    const [publishType, setPublishType] = useState('png');
    const [isRendering, setIsRendering] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isVideoProcessing, setIsVideoProcessing] = useState(false);
    const [activeStep, setActiveStep] = useState(1);
    const [videoProcessed, setVideoProcessed] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [videoError, setVideoError] = useState('');

    useEffect(() => {
        if (selectedImage) {
            setJsonData(selectedImage.template);
        }
    }, [selectedImage]);


    // get all templates on load
    useEffect(() => {
        const url = process.env.REACT_APP_API_BASE_URL + 'templates/';
        fetch(url, {
            method: 'GET', headers: {
                'Content-Type': 'application/json', 'Authorization': `Token ${localStorage.getItem('token')}` // include authentication token in headers
            },
        })
            .then(response => response.json())
            .then(data => {
                setImages(data);
                setInitialImages(data);
            })
            .catch(error => console.error(error));
    }, []);

    const pollRenderJob = async (jobId) => {
        console.log('...  polling ...')
        const pollInterval = setInterval(async () => {
            try {
                const response = await fetch(`https://api.polotno.com/api/renders/${jobId}?KEY=${process.env.REACT_APP_POLOTNO_DEV}`);
                const job = await response.json();

                if (job.status === 'done') {
                    clearInterval(pollInterval);
                    setPreviewNewImage(job.output);
                    setIsRendering(false);
                } else if (job.status === 'error') {
                    clearInterval(pollInterval);
                    console.error('Render job failed:', job.error);
                    setIsRendering(false);
                    // Handle error (show message to user)
                }
            } catch (error) {
                console.error('Error polling render job:', error);
                clearInterval(pollInterval);
                // Handle error (show message to user)
            }
        }, 5000); // Poll every 5 seconds
    };

    const handleUpdate = useCallback(async () => {
        if (selectedImage?.is_video) {
            if (!publishType) {
                setVideoError('Please select an output format (GIF or Video)');
                return;
            }
            if (!videoProcessed) {
                setVideoError('Please process the video before generating');
                return;
            }
        }

        setIsUpdating(true);
        setIsRendering(true);
        setPreviewNewImage('');

        const isVideo = jsonData.pages.some(page => page.children.some(child => child.type === 'video' && child.src));

        try {
            if (isVideo) {
                // Handle video rendering
                const response = await fetch(`https://api.polotno.com/api/renders?KEY=${process.env.REACT_APP_POLOTNO_DEV}`, {
                    method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({
                        design: jsonData,
                        format: publishType,
                        width: jsonData.width,
                        height: jsonData.height,
                        fps: 30,
                        pixelRatio: 1,
                        duration: jsonData.pages[0].duration
                    }),
                });
                const data = await response.json();
                if (data.id) {
                    await pollRenderJob(data.id);
                }
            } else {
                // Handle image rendering
                const response = await fetch(`https://api.polotno.com/api/render?KEY=${process.env.REACT_APP_POLOTNO_DEV}`, {
                    method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({
                        design: jsonData, outputFormat: 'url', exportOptions: {},
                    }),
                });
                const data = await response.json();
                if (data && data.url) {
                    setPreviewNewImage(data.url);
                }
            }
        } catch (error) {
            console.error('Error updating preview:', error);
            // Handle error (show message to user)
        } finally {
            setIsUpdating(false);
            setIsRendering(false);
        }
    }, [jsonData, publishType]);


    function handleAttachImgToPost() {
        // setPostImg(previewNewImage);
        // onClose();
        setPostImg({
            url: previewNewImage, type: publishType
        });
        onClose();
    }

    useEffect(() => {
        if (selectedImage) {
            setCustomInputs(createCustomInputs(selectedImage));
        }
    }, [colorPickerVisibility]);

    const handleColorClick = useCallback((childId) => {
        setColorPickerVisibility(prevState => {
            // Check if the new state is actually different from the old state
            const newState = !prevState[childId];
            if (newState === prevState[childId]) {
                return prevState; // Return old state if no change is necessary
            }

            return {
                ...prevState, [childId]: newState
            };
        });
    }, []);

    const createCustomInputs = useMemo(() => (selectedTemplate, jsonData, setJsonData, colorPickerVisibility, handleColorClick) => {
        if (!selectedTemplate || !jsonData) return [];

        return selectedTemplate.template.pages[0]?.children
            .filter(child => child.styleEditable === true)
            .map(child => {
                switch (child.type) {
                    case 'image':
                        return createImageInput(child, jsonData, setJsonData);
                    case 'text':
                        return createTextInput(child, jsonData, setJsonData, colorPickerVisibility, handleColorClick);
                    case 'video':
                        return createVideoInput(child, jsonData, setJsonData);
                    default:
                        return null;
                }
            });
    }, []);

    function createImageInput(child, jsonData, setJsonData) {
        return (<FormControl fullWidth key={child.id} style={{
                display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginBottom: '24px'
            }}>
                <TextField
                    label="Image URL"
                    id={`x_${child.id}`}
                    variant="outlined"
                    helperText="Add the URL of the image you want to use"
                    style={{marginBottom: 0}}
                    type='url'
                    placeholder={child.src}
                    onChange={event => updateChildProperty(child.id, 'src', event.target.value, jsonData, setJsonData)}
                />
                <img src={child.src}
                     height='200'
                     width='200'
                     style={{marginLeft: 20}}
                     alt="Preview"
                />
            </FormControl>);
    }

    function createTextInput(child, jsonData, setJsonData, colorPickerVisibility, handleColorClick) {
        if (!jsonData || !jsonData.pages || !jsonData.pages[0]) return null;

        const index = jsonData.pages[0].children.findIndex(c => c.id === child.id);
        if (index === -1) return null;

        return (<FormControl fullWidth key={child.id}>
                <TextField
                    label={child.text}
                    id={`x_${child.id}`}
                    type='text'
                    variant="outlined"
                    placeholder={child.text}
                    InputProps={{
                        endAdornment: (<InputAdornment position="end">
                                <Button
                                    variant="outlined"
                                    endIcon={<div style={{
                                        width: '70px',
                                        height: '14px',
                                        borderRadius: '2px',
                                        background: jsonData.pages[0].children[index].fill
                                    }}></div>}
                                    onClick={() => handleColorClick(child.id)}
                                >
                                    Font Color:
                                </Button>
                                {colorPickerVisibility[child.id] && (<div style={{
                                        position: 'absolute', zIndex: 1, top: '50px'
                                    }}>
                                        <HexColorPicker
                                            color={jsonData.pages[0].children[index].fill}
                                            onChange={(color) => updateChildProperty(child.id, 'fill', color, jsonData, setJsonData)}
                                        />
                                    </div>)}
                            </InputAdornment>),
                    }}
                    onChange={event => updateChildProperty(child.id, 'text', event.target.value, jsonData, setJsonData)}
                />
            </FormControl>);
    }

    function createVideoInput(child, jsonData, setJsonData) {
        const handleVideoProcess = async (childId, url, videoDuration) => {
            setIsVideoProcessing(true);
            try {
                await updateChildProperty(childId, 'src', url, jsonData, setJsonData);
                await updateChildProperty(childId, 'duration', videoDuration, jsonData, setJsonData);
                setVideoProcessed(true);
                setVideoUrl(url);
                setActiveStep(3);
                setVideoError(''); // Clear any existing errors
            } catch (error) {
                console.error('Error processing video:', error);
                setVideoError('Failed to process video. Please try again.');
                setVideoProcessed(false);
            } finally {
                setIsVideoProcessing(false);
            }
        };

        const handleVideoError = (errorMessage) => {
            setVideoError(errorMessage);
            setVideoProcessed(false);
            setIsVideoProcessing(false);
        };

        return (
            <FormControl 
                fullWidth 
                key={child.id} 
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    marginBottom: '24px'
                }}
            >
                <Typography variant="subtitle1" gutterBottom>
                    Video Element
                </Typography>
                <VideoEditor
                    onVideoProcess={handleVideoProcess}
                    onError={handleVideoError}
                    childId={child.id}
                    initialVideoUrl={child.src}
                    isProcessing={isVideoProcessing}
                    setVideoProcessed={setVideoProcessed}
                    setIsProcessing={setIsVideoProcessing}
                />
            </FormControl>
        );
    }

    const updateChildProperty = useCallback((childId, property, value, jsonData, setJsonData) => {
        return new Promise((resolve) => {
            setJsonData((prevJsonData) => {
                const jsonDataCopy = {...prevJsonData};
                const newChildren = [...jsonDataCopy.pages[0]?.children];
                const index = newChildren.findIndex(c => c.id === childId);
                if (index !== -1) {
                    newChildren[index] = {...newChildren[index], [property]: value};
                    return {
                        ...jsonDataCopy, pages: [{...jsonDataCopy.pages[0], children: newChildren}]
                    };
                }
                return prevJsonData;
            });
        });
    }, []);

    function isValidUrl(string) {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    }

    function createCustomBackgroundInputs(selectedTemplate) {
        let imgBackground = selectedTemplate?.template?.pages[0]?.background
        let urlStatus = isValidUrl(imgBackground);

        if (urlStatus) {
            return (<FormControl fullWidth
                                 style={{
                                     display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginBottom: '24px'
                                 }}>
                <TextField
                    label="Backgroudnd Image Url"
                    id={`${selectedTemplate?.template?.pages[0]?.custom.name}_background_img`}
                    variant="outlined"
                    helperText="Add the URL of the background image you want to use"
                    style={{marginBottom: 0}}
                    type='url'
                    placeholder={imgBackground}
                    onChange={event => {
                        setJsonData((jsonData) => {
                            const jsonDataCopy = {...jsonData};
                            jsonDataCopy.pages[0].background = event.target.value;
                            return jsonDataCopy;
                        });

                    }}
                />
                <img src={imgBackground}
                     height='200'
                     width='200'
                     style={{marginLeft: 20}}
                     alt="New Image"/>
            </FormControl>)
        }
    }

    function handleClear() {
        // reset form
        if (selectedImage) {
            const initialImage = initialImages.find(img => img.id === selectedImage.id);

            if (initialImage) {
                setSelectedImage(initialImage);
                setJsonData(initialImage.template);
                setCustomInputs(createCustomInputs(initialImage));
                setCustomBackgroundInputs(createCustomBackgroundInputs(initialImage))
            }
        }
        setPreviewNewImage('');
    }

    function handleClearTemplate() {
        // reset form
        if (selectedImage) {
            setSelectedImage('');
            setPreviewNewImage('');
            setJsonData({})
        }
        setPreviewNewImage('');
    }

    const renderVideoSteps = () => (<Box display='flex' flexDirection='column'    width="100%"
        maxWidth={600}
        mx="auto"
        px={2} >
            <StepContainer
                className={activeStep < 1 ? 'disabled' : ''}
                sx={{mb: 2}}
            >
                <Typography 
                variant="h6" 
                color="primary" 
                gutterBottom
                sx={{ 
                    fontSize: { xs: '1.1rem', sm: '1.25rem' },
                    textAlign: { xs: 'center', sm: 'left' }
                  }}>
                    Step 1: Select Output Format
                </Typography>
                <RadioGroup
                    row
                    aria-label="publish-type"
                    name="publish-type"
                    value={publishType}
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        gap: 2,
                        alignItems: 'flex-start',
                        justifyContent: { xs: 'center', sm: 'flex-start' },
                        width: '100%'
                      }}                    
                    onChange={(e) => {
                        setPublishType(e.target.value);
                        setActiveStep(2);
                        setVideoError(''); // Clear any previous errors
                    }}
                >
                    <FormControlLabel
                        value="gif"
                        control={<Radio/>}
                        label="Publish as Gif"
                        sx={{ 
                            margin: 0,
                            flexGrow: { xs: 1, sm: 0 }
                          }}                        
                    />
                    <FormControlLabel
                        value="mp4"
                        control={<Radio/>}
                        label="Publish as Video"
                        sx={{ 
                            margin: 0,
                            flexGrow: { xs: 1, sm: 0 }
                          }}                        
                    />
                </RadioGroup>
            </StepContainer>

            <StepContainer
                className={activeStep < 2 ? 'disabled' : ''}
                sx={{mb: 2}}
            >
                <Typography variant="h6" color="primary" gutterBottom>
                    Step 2: Process Video
                </Typography>
                <Box sx={{mb: 2}}>
                    {customInputs?.filter(input => {
                        const hasVideoEditor = 
                            input?.props?.children?.some?.(child => 
                                child?.type === VideoEditor || 
                                child?.props?.type === VideoEditor
                            ) ||
                            input?.props?.children?.find?.(child => 
                                child?.props?.childId && 
                                child?.props?.initialVideoUrl
                            );
                        
                        return hasVideoEditor;
                    }).map((input, index) => (
                        <Box key={index}>
                            {input}
                        </Box>
                    ))}

                    {videoError && (
                        <Typography 
                            color="error" 
                            variant="body2" 
                            sx={{mt: 1}}
                        >
                            {videoError}
                        </Typography>
                    )}
                    {videoProcessed && (
                        <Typography 
                            color="success.main" 
                            variant="body2" 
                            sx={{mt: 1}}
                        >
                            Video processed successfully!
                        </Typography>
                    )}
                </Box>
            </StepContainer>

            <StepContainer
                className={!videoProcessed ? 'disabled' : ''}
                sx={{mb: 2}}
            >
                <Typography variant="h6" color="primary" gutterBottom>
                    Step 3: Customize Template
                </Typography>
                <Box sx={{mb: 2}}>
                    {customInputs?.filter(input => {
                        // Check if this input contains a video editor
                        const hasVideoEditor = 
                            input?.props?.children?.some?.(child => 
                                child?.type === VideoEditor || 
                                child?.props?.type === VideoEditor
                            ) ||
                            input?.props?.children?.find?.(child => 
                                child?.props?.childId && 
                                child?.props?.initialVideoUrl
                            );

                        // Return true only for non-video inputs
                        return !hasVideoEditor;
                    }).map((input, index) => (<Box key={index} sx={{mb: 2}}>
                            {input}
                        </Box>))}
                    {customBackgroundInputs && (
                        <Box sx={{mt: 2}}>
                            {customBackgroundInputs}
                        </Box>
                    )}
                </Box>
            </StepContainer>
        </Box>);

    useEffect(() => {
        if (selectedImage && jsonData) {
            const inputs = createCustomInputs(selectedImage, jsonData, setJsonData, colorPickerVisibility, handleColorClick);
            setCustomInputs(inputs);
            setCustomBackgroundInputs(createCustomBackgroundInputs(selectedImage));
        }
    }, [selectedImage, jsonData, colorPickerVisibility, handleColorClick, createCustomInputs])

    return (<Dialog open={open} onClose={onClose} fullScreen>
            <AppBar sx={{position: 'relative'}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                        Add Image to Post
                    </Typography>
                    <Button autoFocus sx={{color: 'white!important'}} onClick={onClose}>
                        Close
                    </Button>
                </Toolbar>
            </AppBar>
            {(isRendering || isUpdating || isVideoProcessing) && <CircularProgress/>}
            <Grid container>
                <Grid xs={12} spacing={0}>
                    <Grid justifyContent="space-between" alignItems="stretch" container>
                        {/*
                            if user wants to select a template
                        */}
                        <>
                            <Grid style={{paddingLeft: '24px'}} item xs={3}>
                                {!selectedImage && (


                                    <ImageListViewWrapper cellHeight={160} cols={1}>
                                        {/*<ImageList cellHeight={160} className={classes.imageList} cols={1}>*/}
                                        {images.map((img) => (<ImageListItem key={img.id} onClick={() => {
                                            setSelectedImage(img);
                                            setPreviewNewImage('');
                                            setJsonData(img.template)
                                        }}>
                                            <img src={img.preview_img} alt={img.name}/>
                                            <ImageListItemBar
                                                title={img.name}
                                                // classes={{
                                                //   root: classes.titleBar,
                                                //   title: classes.title,
                                                // }}
                                                actionIcon={<IconButton size="large">
                                                    <InfoIcon/>
                                                </IconButton>}
                                            />
                                        </ImageListItem>))}
                                    </ImageListViewWrapper>)}
                            </Grid>
                            <Grid height={100} item xs={!selectedImage ? 9 : 12}>
                                {!selectedImage ? (<FeedbackWrapper>
                                    <div>
                                        <Typography variant="h5">
                                            Select a template to start
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography variant="body1">
                                            Select an template to start
                                        </Typography>
                                    </div>
                                </FeedbackWrapper>) : (<Box>
                                        <FeedbackWrapper>
                                            <Grid container justifyContent="center">
                                                <Grid item>
                                                    <>
                                                        <Grid container spacing={4}
                                                              sx={{display: 'flex', justifyContent: 'space-between'}}>
                                                            {/* Left Column */}
                                                            <Grid item xs={12} md={6}>
                                                                {selectedImage && (<Grid container direction="column"
                                                                                         alignItems="center">
                                                                        <ImgViewWrapper>
                                                                            <ImageList cellHeight={100} mb={2}
                                                                                       padding={2} cols={1}>
                                                                                <ImageListItem>
                                                                                    <img
                                                                                        width="100%"
                                                                                        src={selectedImage.preview_img}
                                                                                        alt="selected layout"
                                                                                        loading="lazy"
                                                                                    />
                                                                                </ImageListItem>
                                                                            </ImageList>
                                                                            <Button
                                                                                variant="contained"
                                                                                mb={2}
                                                                                mt={2}
                                                                                color="primary"
                                                                                onClick={handleClearTemplate}
                                                                            >
                                                                                Select a different Template
                                                                            </Button>
                                                                        </ImgViewWrapper>
                                                                    </Grid>)}
                                                            </Grid>

                                                            {/* Right Column */}
                                                            <Grid item xs={12} md={6}>
                                                                {previewNewImage ? (<ImgViewWrapper>
                                                                        {publishType === 'mp4' ? (
                                                                            <video width="100%" height="100%" controls>
                                                                                <source src={previewNewImage}
                                                                                        type="video/mp4"/>
                                                                                Your browser does not support the video
                                                                                tag.
                                                                            </video>) : publishType === 'gif' ? (<img
                                                                                src={previewNewImage}
                                                                                alt="Generated GIF"
                                                                                style={{
                                                                                    width: '100%', height: '100%'
                                                                                }}
                                                                            />) : (<img
                                                                                height="100%"
                                                                                width="100%"
                                                                                src={previewNewImage}
                                                                                alt="selected"
                                                                            />)}
                                                                    </ImgViewWrapper>) : (<>
                                                                        <Typography sx={{
                                                                            fontSize: {xs: '1.2rem', sm: '1.25rem'}, // Smaller font on mobile
                                                                            textAlign: 'center',
                                                                            mb: 2
                                                                        }} variant="h5">
                                                                            {selectedImage?.is_video ? 'Video Template Configuration' : 'Update Template Elements'}
                                                                        </Typography>
                                                                        {selectedImage?.is_video ? (
                                                                            <Box sx={{width: '100%', p: 2}}>
                                                                                {renderVideoSteps()}
                                                                            </Box>) : (<div>
                                                                                {customInputs}
                                                                                {customBackgroundInputs}
                                                                            </div>)}

                                                                    </>)}

                                                                <Box
                                                                    display="flex"
                                                                    flexDirection="row"
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                    gap={2}
                                                                    mt={2}
                                                                >
                                                                    {selectedImage && !previewNewImage && (<Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={handleUpdate}
                                                                        >
                                                                            Generate New Image
                                                                        </Button>)}
                                                                    {previewNewImage && (<Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={handleAttachImgToPost}
                                                                        >
                                                                            Add Image to Post
                                                                        </Button>)}
                                                                    {previewNewImage && (<Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={handleClear}
                                                                        >
                                                                            Edit Image
                                                                        </Button>)}
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                </Grid>
                                            </Grid>


                                        </FeedbackWrapper>
                                    </Box>)}

                            </Grid>
                        </>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>);
};

export default ImageGalleryDialog;
