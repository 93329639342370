import React, {useCallback, useState} from 'react';
import {
    AppBar,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import styled from '@emotion/styled';
import {Link} from "react-router-dom";
import {useNavigate} from "react-router";
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import {useAuth} from "../providers/AuthProvider";
import {logError} from "../utils/logger";
import {ROUTES} from "../constants";
import ErrorBoundary from "../components/ErrorBoundary";
import SoloSocialLogo from "../components/SoloSocialLogo";
import {useFeatureAccess} from "../hooks/useFeatureAccess";

const SkipLink = styled('a')(({theme}) => ({
    position: 'absolute',
    left: '-999px',
    width: '1px',
    height: '1px',
    top: 'auto',
    overflow: 'hidden',
    zIndex: 1500,
    padding: theme.spacing(1),
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:focus': {
        left: theme.spacing(2),
        width: 'auto',
        height: 'auto',
        overflow: 'visible',
        outline: `2px solid ${theme.palette.primary.main}`,
    },
}));

const LogoLink = styled(Link)(({theme}) => ({
    position: 'relative',
    marginRight: '60px',
    '&::after': {
        content: "'alpha'",
        position: 'absolute',
        top: '0',
        right: '-36px',
        backgroundColor: theme.palette.grey[50],
        padding: '1px 5px',
        borderRadius: '2px',
        fontSize: '0.5em',
        color: theme.palette.grey[200],
    }
}));

const StyledButton = styled(Button)(({theme}) => ({
    marginRight: theme.spacing(2),
}));

const MobileMenu = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
}));

function TopNavBar() {
    const {isAuthenticated, logout} = useAuth();
    const { canAccessTemplateEditor, canCreatePosts } = useFeatureAccess();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);

    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleMobileMenuOpen = useCallback((event) => {
        setMobileMenuAnchorEl(event.currentTarget);
    }, []);

    const handleMobileMenuClose = useCallback(() => {
        setMobileMenuAnchorEl(null);
    }, []);

    const handleLogout = useCallback((event) => {
        event.preventDefault();
        try {
            logout();
            navigate(ROUTES.HOME, {replace: true});
        } catch (error) {
            logError('Logout error', error);
        }
    }, [logout, navigate]);

    const renderMenu = () => (
        <Stack direction={isMobile ? "column" : "row"} spacing={2}>
            {isAuthenticated ? (
                <>
                    {canAccessTemplateEditor && (
                        <StyledButton variant="outlined" component={Link} to={ROUTES.EDITOR}>Create a
                            Template
                        </StyledButton>
                    )}
                    {canCreatePosts && (
                        <StyledButton variant="contained" component={Link} to={ROUTES.CREATE_POST}>Create a
                        Post
                        </StyledButton>
                    )}
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleClick}
                        color="inherit"
                    >
                        <AccountCircle/>
                    </IconButton>
                </>
            ) : (
                <>
                    <Button component={Link} to={ROUTES.LOGIN} color="inherit">Sign In</Button>
                    <Button variant="outlined" component={Link} to={ROUTES.SIGNUP} color="primary">Get Started</Button>
                </>
            )}
        </Stack>
    );

    return (
        <ErrorBoundary>
            <SkipLink href="#main-content">
                Skip to main content
            </SkipLink>
            <AppBar elevation={0} position="fixed" color="inherit">
                <Container maxWidth="lg">
                    <Toolbar component="nav" aria-label="Main navigation">
                        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                            <LogoLink to={isAuthenticated ? ROUTES.DASHBOARD : ROUTES.HOME}>
                                <SoloSocialLogo fontSize="large" />
                            </LogoLink>
                        </Typography>
                        {isMobile ? (
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={handleMobileMenuOpen}
                            >
                                <MenuIcon/>
                            </IconButton>
                        ) : (
                            renderMenu()
                        )}
                    </Toolbar>
                </Container>
            </AppBar>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose} component={Link} to={ROUTES.PROFILE}>Profile</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
            <Menu
                anchorEl={mobileMenuAnchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(mobileMenuAnchorEl)}
                onClose={handleMobileMenuClose}
            >
                <MobileMenu>
                    {renderMenu()}
                </MobileMenu>
            </Menu>
        </ErrorBoundary>
    );
}

TopNavBar.propTypes = {
    // Add any props if needed
};

export default React.memo(TopNavBar);