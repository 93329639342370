import { createTheme } from '@mui/material/styles';

// Define font families as constants
const FONT_FAMILY_HEADERS = "'Uncut Sans', sans-serif";
const FONT_FAMILY_BODY = "'Lato', sans-serif";

// Define custom font faces
const customFontFaces = `
  @font-face {
    font-family: 'Uncut Sans';
    src: url('/fonts/UncutSans-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
`;

// Add custom font faces to document
const style = document.createElement('style');
style.textContent = customFontFaces;
document.head.appendChild(style);

// Define palette
const palette = {
  common: {
    black: '#000120',
    white: '#f8f8f8',
  },
  background: {
    default: '#f8f8f8',
  },
  grey: {
    40: '#f6f6f6',
    50: '#EFE7DA',
    80: '#eff1f6',
    100: '#5a5855',
    200: '#363638',
    300: '#212121',
  },
  primary: {
    main: "#000120",
  },
  secondary: {
    main: '#FDC727',
  },
  error: {
    main: '#F67066',
  },
  warning: {
    main: '#F6BC66',
  },
  info: {
    main: '#668EF6',
  },
  success: {
    main: '#8FD359',
  },
};

// Create theme
const theme = createTheme({
  palette,
  typography: {
    fontFamily: FONT_FAMILY_BODY,
    // Header styles
    h1: {
      fontFamily: FONT_FAMILY_HEADERS,
      fontSize: '3.5rem',
      fontWeight: 700,
      '@media (max-width:600px)': {
        fontSize: '2.5rem',
      },
    },
    h2: {
      fontFamily: FONT_FAMILY_HEADERS,
      fontSize: '3rem',
      fontWeight: 700,
      '@media (max-width:600px)': {
        fontSize: '2rem',
      },
    },
    h3: {
      fontFamily: FONT_FAMILY_HEADERS,
      fontSize: '2.5rem',
      fontWeight: 700,
      '@media (max-width:600px)': {
        fontSize: '1.75rem',
      },
    },
    h4: {
      fontFamily: FONT_FAMILY_HEADERS,
      fontWeight: 700,
    },
    h5: {
      fontFamily: FONT_FAMILY_HEADERS,
      fontWeight: 700,
    },
    h6: {
      fontFamily: FONT_FAMILY_HEADERS,
      fontWeight: 700,
    },
    // Body text styles
    body1: {
      fontFamily: FONT_FAMILY_BODY,
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    body2: {
      fontFamily: FONT_FAMILY_BODY,
      fontSize: '0.875rem',
      lineHeight: 1.43,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '@font-face': [
            {
              fontFamily: 'Uncut Sans',
              fontStyle: 'normal',
              fontWeight: 700,
              fontDisplay: 'swap',
              src: `url('/fonts/UncutSans-Bold.woff') format('woff')`,
            },
          ],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontWeight: 700,
          fontFamily: FONT_FAMILY_BODY,
        },
        containedPrimary: {
          backgroundColor: palette.common.black,
          color: palette.common.white,
          '&:hover': {
            backgroundColor: palette.grey[200],
          },
        },
        outlined: {
          color: palette.common.black,
          borderColor: palette.common.black,
        },
        text: {
          color: palette.common.black,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: '24px',
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: palette.common.black,
            },
            '&.Mui-focused fieldset': {
              borderColor: palette.common.black,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: palette.common.black,
          },
        },
      },
    },
  },
});

export default theme;