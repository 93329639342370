import React from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useStatus } from "../providers/MsgStatusProvider";

const TikTokConnectButton = () => {
    const navigate = useNavigate();
    const { setStatusType, setStatusMessage, setIsAlertOpen } = useStatus();

    const handleConnect = () => {
        const url = `${process.env.REACT_APP_API_BASE_URL}connect/tiktok/auth/`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            },
        })
        .then((response) => response.json())
        .then((data) => {
            if (data.redirect_url) {
                window.location.href = data.redirect_url;
            } else {
                throw new Error('No redirect URL provided');
            }
        })
        .catch((error) => {
            console.error(error.message);
            setIsAlertOpen(true);
            setStatusMessage(error.message);
            setStatusType('error');
        });
    };

    return (
        <Button variant="contained" color="primary" onClick={handleConnect}>
            Connect to TikTok
        </Button>
    );
};

export default TikTokConnectButton;