import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from "@mui/material/Alert";
import FormControl from '@mui/material/FormControl';
import {useAuth} from "../providers/AuthProvider";
import {useNavigate} from "react-router";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {useStatus} from "../providers/MsgStatusProvider";
import Link from "@mui/material/Link";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {IconButton, InputAdornment} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";


function LoginForm() {
    // const classes = useStyles();
    const auth = useAuth();
    const {statusType, setStatusMessage, setIsAlertOpen, setStatusType} = useStatus();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Save or remove email from localStorage based on remember me checkbox
        if (rememberMe) {
            localStorage.setItem('rememberedEmail', username);
        } else {
            localStorage.removeItem('rememberedEmail');
        }

        await auth.login(username, password, timezone);
    };

    useEffect(() => {
        if (auth.user.length > 0) {
            navigate('/dashboard', {replace: true});
        }
    }, [auth.user]);

    useEffect(() => {
        const savedEmail = localStorage.getItem('rememberedEmail');
        if (savedEmail) {
            setUsername(savedEmail);
            setRememberMe(true);
        }
    }, []);

    useEffect(() => {
        if (auth.errorMessage.error) {
            setStatusMessage(auth.errorMessage.error)
            setStatusType('error');
            setIsAlertOpen(true)

        } else {
            setStatusMessage('');
            setIsAlertOpen(false);
        }
    }, [auth.errorMessage]);


    function handleRememberMeChange(event) {
        setRememberMe(event.target.checked);
        if (!event.target.checked) {
            localStorage.removeItem('rememberedEmail');
        }
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    function handleHideShowPassword() {
        setIsPasswordVisible(!isPasswordVisible);
    }

    return (
        <Grid contaner>
            <Grid item p={3} direction="column" spacing={2} justifyItems="center">
                {/* Left side */}
                <div>
                    <FormControl variant="standard">
                        {auth.isAuthenticated ? (
                            <Typography variant="h3" align="center">
                                You are already logged in
                            </Typography>
                        ) : (<>
                                <Typography variant="h1" align="center">
                                    Login
                                </Typography>
                                {auth.errorMessage?.length > 0 &&
                                    auth.errorMessage.map((error, index) => (
                                        <Alert key={index} severity={statusType}>
                                            {error}
                                        </Alert>
                                    ))}

                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="username"
                                        label="Email"
                                        // className={classes.textField}
                                        type="text"
                                        value={username}
                                        onChange={(event) => setUsername(event.target.value)}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="password"
                                        label="Password"
                                        type={isPasswordVisible ? 'text' : 'password'}
                                        value={password}
                                        onChange={(event) => setPassword(event.target.value)}
                                        margin="normal"
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleHideShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {isPasswordVisible ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={rememberMe}
                                                onChange={handleRememberMeChange}
                                                name="rememberMe"
                                                color="primary"
                                            />
                                        }
                                        label="Remember me"
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth={true}
                                        type="submit"
                                    >
                                        Log in
                                    </Button>
                                    <Box display="flex" flexDirection="column" gap={2}>
                                        <Link href="/forgot-password" variant="body2">
                                            Forgot password?
                                        </Link>
                                        <Link href="/signup" variant="body2">
                                            Don't have an account? Sign Up
                                        </Link>
                                    </Box>
                                </form>
                            </>
                        )}
                    </FormControl>
                </div>
            </Grid>
        </Grid>
    );
}

export default LoginForm;
