import React, {useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';
import {useAuth} from "./AuthProvider";
import {useNavigate} from "react-router";
import {useStatus} from "./MsgStatusProvider";

export const PrivateRoutes = ({children, userDetail}) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isPaymentNeeded, setIsPaymentNeeded] = useState(false);
    const {setStatusMessage, setStatusType, setIsAlertOpen} = useStatus();
    // Run the useEffect only once when the component mounts
    useEffect(() => {
        if (userDetail !== undefined) {
            setIsLoading(false);
        }
        isPaidAccount() // Check Account status
    }, [userDetail]);

    const isPaidAccount = () => {
        let subscriptionDetailsStatus = userDetail?.userprofile?.subscription?.status

        if ( subscriptionDetailsStatus === 'canceled' || subscriptionDetailsStatus === 'blocked') {
            setIsPaymentNeeded(true)
        }

    }

    useEffect(() => {
        if (!isLoading && auth.isAuthenticated && localStorage.getItem('token')) {
            if (isPaymentNeeded) {
                if (location.pathname !== '/profile') {
                    navigate('/profile');
                }
                setStatusMessage("You're account is missing payment. If you don't believe you should see this message please contact support.");
                setIsAlertOpen(true);
                setStatusType('error');
            }
        } else if (auth.isAuthenticated && localStorage.getItem('token') && !isPaymentNeeded) {
            if (location.state && location.state.from) {
                navigate(location.state.from.pathname);
            }
        }
    }, [auth.isAuthenticated, userDetail, navigate, isLoading, location.pathname]);

    if (isLoading) {
        // This is where you might render a spinner or some loading text
        return <div>Loading...</div>;
    }

    if (!auth.isAuthenticated) {
        return <Navigate to='/login'/>;
    }
    return children
};
