import {Box, IconButton} from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import BlueskyLogo from "./BlueskyLogo";
import React from "react";
import ThreadsLogo from "./ThreadsLogo";

const RenderSocialIcons = ({post}) => {
    return (
        <Box display="flex" gap={0.5}>
            {post.platform_details.map((account) => {
                switch (account.platform.toLowerCase()) {
                    case 'twitter':
                        return <><IconButton aria-label={`${account.platform} post`}><TwitterIcon
                            aria-label={`${account.platform} post`} key={account.id}
                            color={account.is_posted ? "success" : "default"} fontSize="small"/></IconButton></>;
                    case 'linkedin':
                        return <><IconButton aria-label={`${account.platform} post`}><LinkedInIcon
                            aria-label={`${account.platform} post`} key={account.id}
                            color={account.is_posted ? "success" : "default"} fontSize="small"/></IconButton></>;
                    case 'threads':
                        return <><IconButton aria-label={`${account.platform} post`}><ThreadsLogo
                            aria-label={`${account.platform} post`} key={account.id}
                            color={account.is_posted ? "success" : "default"} fontSize="small"/></IconButton></>;
                    case 'instagram':
                        return <><IconButton aria-label={`${account.platform} post`}><InstagramIcon
                            aria-label={`${account.platform} post`} key={account.id}
                            color={account.is_posted ? "success" : "default"} fontSize="small"/></IconButton></>;
                    case 'bluesky':
                        return <><IconButton aria-label={`${account.platform} post`}><BlueskyLogo
                            aria-label={`${account.platform} post`} key={account.id}
                            color={account.is_posted ? "success" : "default"} fontSize="small"/></IconButton></>;
                    default:
                        return null;
                }
            })}
        </Box>
    );
};

export default RenderSocialIcons;