import {PLAN_FEATURES} from "../constants/permissions";
import {useAuth} from "../providers/AuthProvider";


export const useFeatureAccess = () => {
  const { user } = useAuth();

  const getUserPlan = () => {
    const planName = user?.[0]?.userprofile?.subscription_plan?.name;
    return PLAN_FEATURES[planName?.toUpperCase()?.replace(' ', '_')] || null;
  };

  const checkPermission = (permission) => {
    const userPlan = getUserPlan();
    return userPlan?.permissions?.[permission];
  };

  return {
    canAccessTemplateEditor: checkPermission('canAccessTemplateEditor'),
    canCreateMedia: checkPermission('canCreateMedia'),
    canAccessImageGallery: checkPermission('canAccessImageGallery'),
    canAccessSocialConnections: checkPermission('canAccessSocialConnections'),
    canCreatePosts: checkPermission('canCreatePosts'),
    currentPlan: getUserPlan()?.name
  };
};