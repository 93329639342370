import React from 'react';
import Button from '@mui/material/Button';
import {useStatus} from "../providers/MsgStatusProvider";

const ThreadsConnectButton = () => {
    const {setStatusType, setStatusMessage, setIsAlertOpen} = useStatus();

    const handleConnect = () => {
        const url = `${process.env.REACT_APP_API_BASE_URL}connect/threads/auth/`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            },
        })
            .then((response) => response.json())
            .then((data) => {
                window.location.href = data.redirect_url;
            })
            .catch((error) => {
                console.error(error.message);
                setIsAlertOpen(true);
                setStatusMessage(error.message);
                setStatusType('error');
            });
    };

    return (
        <Button variant="contained" color="primary" onClick={handleConnect}>
            Connect to Threads
        </Button>
    );
};

export default ThreadsConnectButton;